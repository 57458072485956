(function(module) {
try {
  module = angular.module('portfolioGulpAngularApp');
} catch (e) {
  module = angular.module('portfolioGulpAngularApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/awardsComponent/awardsComponent.html',
    '<div ng-controller="AwardsComponentController" class="id_awardsHolder"><h2>Awards</h2><p>I\'ve won some awards too, have a look.</p><div ng-repeat="award in awards" class="id_awardHolder"><img ng-src="{{trophySrc}}" class="trophyLogo"><div class="id_awardTextHolder"><h3>{{award.title}}</h3><p>{{award.project}}</p></div></div></div>');
}]);
})();

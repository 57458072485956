angular.module('portfolioGulpAngularApp')

.directive('topBannerComponent', [function() {
        return {
            restrict: 'A',
            templateUrl: 'components/topBannerComponent/topBannerComponent.html'
        };
    }])
.controller('TopBannerComponentController', ['$scope', function ($scope){
	$scope.logoSrc = 'images/logo.svg';
	$scope.topBannerSlantImgSrc = 'images/topBannerSlant.svg';
	$scope.linkedinAddress = 'https://www.linkedin.com/in/richardbangay';
	$scope.emailAddress = 'richard.bangay@gmail.com';
}]);
angular.module('portfolioGulpAngularApp')

.directive('introComponent', [function() {
        return {
            restrict: 'A',
            templateUrl: 'components/introComponent/introComponent.html'
        };
    }])

.controller('IntroComponentController', ['$scope', function ($scope){
	$scope.introText = 'I\'m Richard Bangay, an award winning front-end web developer based in London. I\'ve held senior development roles at both the BBC and the Guardian and have over 10 years\' experience delivering high performance, responsive, interactive content to millions of viewers. I specialise in the following technologies/libraries/skills:';
	$scope.techLogos = [
		{'logoSrc': ['images/html5Logo.svg'], 'title': 'HTML5'},
		{'logoSrc': ['images/css3Logo.svg'], 'title': 'CSS3'},
		{'logoSrc': ['images/sassLogo.svg'], 'title': 'SASS'},
		{'logoSrc': ['images/es6Logo.svg'], 'title': 'Javascipt'},
		{'logoSrc': ['images/jqueryLogo.svg'], 'title': 'JQuery'},
		{'logoSrc': ['images/requirejsLogo.svg'], 'title': 'RequireJs'},
		{'logoSrc': ['images/reactjsLogo.svg'], 'title': 'ReactJs'},
		{'logoSrc': ['images/angularjsLogo.svg'], 'title': 'AngularJs'},
		{'logoSrc': ['images/svgLogo.svg'], 'title': 'SVG'},
		{'logoSrc': ['images/d3Logo.svg'], 'title': 'D3'},
		{'logoSrc': ['images/gruntLogo.svg'], 'title': 'Grunt'},
		{'logoSrc': ['images/gulpLogo.svg'], 'title': 'Gulp'},
		{'logoSrc': ['images/webpackLogo.svg'], 'title': 'Webpack'},
		{'logoSrc': ['images/nodejsLogo.svg'], 'title': 'NodeJs'}
	];
	$scope.aboutPicSrc = 'images/aboutPic.jpg';
	$scope.introTextSecondHalf = 'I\'m available for both contract and freelance work, so feel free to get in touch via my email address richard.bangay@gmail.com or my [[text:linkedin, link:https://www.linkedin.com/in/richardbangay, target:_blank]] page if you have a project that requires any of the above skills.';
}])

.filter('customFilter', function () {
	return function(inputStr) {
		return '<a href="http://google.com">Hi there</a>';
	};
});
angular.module('portfolioGulpAngularApp').directive('setProjectCollumnsDirective', ['$window', '$timeout', function($window, $timeout) {
    return {
        restrict: 'A',
		scope: {
            'collumnVal': '=',
            'collumnNumBreakpoints': '='          
        },
        link: function (scope, element, attr) {

        	/*
        	 * variables
        	*/
        	var windowWidth;

        	angular.element($window).bind('resize', setCollumnSize);
        	setCollumnSize();

        	function setCollumnSize() {
        		
        		windowWidth = $window.innerWidth;

				/*
				 * Loop through the break points and match the bounds
				*/
				var a, breakpointsLength = scope.collumnNumBreakpoints.length, closestBreakPointMatch;
				for (a = 0; a < breakpointsLength; a++) {
					var breakpointObj = scope.collumnNumBreakpoints[a];
					if (windowWidth >= (breakpointObj.minWidth | 0) && windowWidth <= (breakpointObj.maxWidth | 999999)) {
						
						breakpointObj.distance = Math.abs(windowWidth - Math.min(Math.min((breakpointObj.minWidth | 0), windowWidth), Math.min((breakpointObj.maxWidth | 999999), windowWidth)));

						if (!closestBreakPointMatch) {
							closestBreakPointMatch = breakpointObj;
						}
						else if (breakpointObj.distance <= closestBreakPointMatch.distance) {
							closestBreakPointMatch = breakpointObj;
						}
					}
				}

				$timeout(function(){
					scope.collumnVal = closestBreakPointMatch.val;
				});
        	}
        }
    };
}]);
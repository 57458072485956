(function(module) {
try {
  module = angular.module('portfolioGulpAngularApp');
} catch (e) {
  module = angular.module('portfolioGulpAngularApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/introComponent/introComponent.html',
    '<div ng-controller="IntroComponentController" class="id_introHolder"><h2>About</h2><div class="id_introPHolder"><p>{{introText}}</p><div class="id_techLogosHolder"><div ng-repeat="logo in techLogos" class="id_techLogoHolder"><img ng-repeat="icon in logo.logoSrc" ng-src="{{icon}}" class="id_techLogo"><h3>{{logo.title}}</h3></div></div><p>I\'m available for both contract and freelance work, so feel free to get in touch via my email address richard.bangay@gmail.com or my <a href="https://www.linkedin.com/in/richardbangay" target="_blank">linkedin</a> page if you have a project that requires any of the above skills.</p></div></div>');
}]);
})();

angular.module('portfolioGulpAngularApp')

.directive('projectsComponent', ['$window', function($window) {
        return {
            restrict: 'A',
            templateUrl: 'components/projectsComponent/projectsComponent.html'
        };
    }])
.controller('ProjectsComponentController', ['$scope', function ($scope){
	$scope.projectsModel = {
		numOfCollumns: 1,
		projects: [
			{
				'projectTitle': 'Business tax account',
				'projectDescription': 'Part of the GDS digital transformation. Business tax account gives bussinesses access to advice and information, and lets them see their tax transactions',
				'projectImgPath': 'images/projects/bta.png',
				'projectLink': 'https://www.gov.uk/performance/your-tax-account'
			},
			{
				'projectTitle': 'Tracking a month of deadly attacks',
				'projectDescription': 'Visualising jihadi attacks during November 2014.',
				'projectImgPath': 'images/projects/attacksProject.png',
				'projectLink': 'http://www.bbc.co.uk/news/world-30080914'
			},
			{
				'projectTitle': 'Will a robot take your job?',
				'projectDescription': 'Based on data from Oxford University, find out how likely it is that your job will become automated.',
				'projectImgPath': 'images/projects/robots.png',
				'projectLink': 'http://www.bbc.co.uk/news/technology-34066941'
			},
			{
				'projectTitle': 'Wimbledon time machine',
				'projectDescription': 'Explore the history of the famous tournament.',
				'projectImgPath': 'images/projects/wimbledonTimeMachine.png',
				'projectLink': 'http://www.bbc.co.uk/news/resources/idt-03f68d31-1ffa-4985-8076-15ba81333c96'
			},
			{
				'projectTitle': 'The price of football',
				'projectDescription': 'Gathering data about season and match-day ticket prices, pies, programmes, tea and away tickets from clubs across the UK.',
				'projectImgPath': 'images/projects/priceOfFootball.png',
				'projectLink': 'http://www.bbc.co.uk/news/resources/idt-79ccb1e7-d390-44c1-8857-cfcb0ade2595'
			},
			{
				'projectTitle': 'How equal are you?',
				'projectDescription': 'Find out how your country ranks for gender equality.',
				'projectImgPath': 'images/projects/howEqualAmI.png',
				'projectLink': 'http://www.bbc.co.uk/news/world-34808717'
			},
			{
				'projectTitle': 'Election 2015: turnout maps',
				'projectDescription': 'Examination of turnout, gains and share of the vote for the 2015 general election.',
				'projectImgPath': 'images/projects/electionMaps.png',
				'projectLink': 'http://www.bbc.co.uk/news/election-2015-32624405'
			},
			{
				'projectTitle': 'Domestic violence in India',
				'projectDescription': 'Analysing domestic violence crime data in India.',
				'projectImgPath': 'images/projects/hundredWomenIndia.png',
				'projectLink': 'http://www.bbc.co.uk/news/world-asia-india-29708612'
			},
			{
				'projectTitle': 'Surviving childhood in Africa',
				'projectDescription': 'Millennium development goals progress in Malawi and Africa as a whole.',
				'projectImgPath': 'images/projects/malawi.png',
				'projectLink': 'http://www.bbc.co.uk/news/health-29161620'
			},
			{
				'projectTitle': 'London languages',
				'projectDescription': 'London is one of the most linguistically interesting cities in the world, with more than 100 languages in use across the city.',
				'projectImgPath': 'images/projects/londonLanguages.png',
				'projectLink': 'http://www.bbc.co.uk/news/resources/idt-1e4fbcb9-5bd6-4e14-adf5-bf3ab1ba79bb'
			},
			{
				'projectTitle': 'Bodyclock',
				'projectDescription': 'Explore the eight body clock phases.',
				'projectImgPath': 'images/projects/bodyClock.svg',
				'projectLink': 'http://www.bbc.co.uk/news/health-27161671'
			},
			{
				'projectTitle': 'The rise of Islamic State',
				'projectDescription': 'Charting the rise to power of militant jihadist group Islamic State.',
				'projectImgPath': 'images/projects/riseOfIsis.png',
				'projectLink': 'http://www.bbc.co.uk/news/world-middle-east-28116033'
			},
			{
				'projectTitle': 'Arms wide open',
				'projectDescription': 'Long form article exploring the story behind Cristo Redentor statue.',
				'projectImgPath': 'images/projects/shorthand.png',
				'projectLink': 'http://www.bbc.co.uk/news/special/2014/newsspec_7141/index.html'
			},
			{
				'projectTitle': 'Movie mashup',
				'projectDescription': 'Guardian Films movie mashup: make a cartoon character based on your favourite films of 2013.',
				'projectImgPath': 'images/projects/movieMashup.png',
				'projectLink': 'http://www.theguardian.com/film/ng-interactive/2013/top-films-2013-movie-mashup'
			},
			{
				'projectTitle': 'Australian election 2013: live results',
				'projectDescription': 'Live election results of the 2013 Australian election for the Guardian.',
				'projectImgPath': 'images/projects/australiaElection2013.jpg',
				'projectLink': ''
			},
			{
				'projectTitle': 'Snackville',
				'projectDescription': 'Online healthy eating game for the British Heart Foundation.',
				'projectImgPath': 'images/projects/snackville.jpg',
				'projectLink': ''
			},
			{
				'projectTitle': 'AV referendum: live results',
				'projectDescription': 'Live results of the UK AV referendum for the electoral commission. Online and in situe on screens in counting stations across the country.',
				'projectImgPath': 'images/projects/avReferendum.jpg',
				'projectLink': ''
			}
		]
	};
}]);
angular.module('portfolioGulpAngularApp')

.directive('awardsComponent', [function() {
        return {
            restrict: 'A',
            templateUrl: 'components/awardsComponent/awardsComponent.html'
        };
    }])
.controller('AwardsComponentController', ['$scope', function ($scope){
	$scope.trophySrc = 'images/trophySmall.svg';
	$scope.awards = [
		{
			'title': 'RSS Statistical Excellence in Journalism',
			'project': 'Jihadism: Tracking a month of deadly attacks'
		},
		{
			'title': 'Bronze Lovie Award',
			'project': 'Interactive video projects'
		},
		{
			'title': 'Association of British Science Writers Award',
			'project': 'BBC bodyclock'
		},
		{
			'title': 'Endocrine Society Award for Excellence in Journalism',
			'project': 'BBC bodyclock'
		},
		{
			'title': 'Medical Journalists Association awards.',
			'project': 'Surviving childhood in Africa'
		},
		{
			'title': 'Online Media Award: Best video journalism',
			'project': 'Rise of the Islamic state'
		}
	];
}]);
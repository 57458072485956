(function(module) {
try {
  module = angular.module('portfolioGulpAngularApp');
} catch (e) {
  module = angular.module('portfolioGulpAngularApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('components/home.html',
    '<div top-banner-component></div><div intro-component></div><div awards-component></div><div projects-component></div>');
}]);
})();
